import React from "react";
import LayoutMain from "../../layouts/layout-main";
import { useState, useEffect } from "react";
import { Card } from "react-rainbow-components";
import { HiCurrencyDollar, HiCalendarDays, HiTicket } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { GiMoneyStack } from "react-icons/gi";
import { NavLink } from "react-router-dom";
import { HiPlus } from "react-icons/hi2";
import CryptoJS from "crypto-js";
import config from "../../config";
import API_URLS from "../../config";
import axios from "axios";
import { format } from "date-fns";
import { id } from "date-fns/locale";
import { EncryptStorage } from "encrypt-storage";
import { FaFilter, FaCircleCheck } from "react-icons/fa6";
import { type } from "@testing-library/user-event/dist/type";
import FaqDashboard from "./faq";
import PetKunjungan from "./pet-kunjungan";

export const encryptStorage = new EncryptStorage("//FatmaW4T!_154", {
  storageType: "sessionStorage",
});

const Dashboard = () => {
  useEffect(() => {
    document.title = "Saloka Theme Park";
  }, []);

  const today = new Date();
  const formattedDate = format(today, "EEEE, dd MMMM yyyy", { locale: id });
  const [ListBill, setListBill] = React.useState([]);
  const [reservation, setReservation] = React.useState([]);

  // const [totalRevenue, setTotalRevenue] = React.useState(0);
  let totalRevenue = 0;
  let totalTagihan = 0;
  let totalTicket = 0;
  let totalGross = 0;
  const [detailPartner, setDetailPartner] = useState([]);
  const [totalInsentivePartner, setTotalInsentivePartner] = useState(0);

  //Filter Dashboard
  const [showFilterDashboard, setShowFilterDashboard] = useState(false);
  const [valueDateFilter, setValueDateFilter] = useState("monthly");

  const handleShowFilterDashboard = () => {
    setShowFilterDashboard(!showFilterDashboard);
    console.log(showFilterDashboard);
  };

  const handleDateFilter = (e) => {
    const value = e.target.getAttribute("filter");
    if (value === "monthly") {
      setValueDateFilter("monthly");
    } else {
      setValueDateFilter("");
    }
    setShowFilterDashboard(!showFilterDashboard);
  };

  useEffect(() => {
    console.log(valueDateFilter);
  }, [valueDateFilter]);

  const decryptData = (encryptedData, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  };

  const getUsernameFromLocalStorage = (secretKey) => {
    const encryptedPartnerID = sessionStorage.getItem("encryptedpartnerID");
    if (encryptedPartnerID) {
      const decryptedPartnerID = decryptData(encryptedPartnerID, secretKey);
      return decryptedPartnerID;
    }
    return null;
  };

  const getPartnernameFromLocalStorage = (secretKey) => {
    const encryptedpartnerName = sessionStorage.getItem("encryptedpartnerName");
    if (encryptedpartnerName) {
      const decryptedPartnerName = decryptData(encryptedpartnerName, secretKey);
      return decryptedPartnerName;
    }
    return null;
  };

  const secretKey = config.secretKey;
  const partnerId = getUsernameFromLocalStorage(secretKey);
  const partnerName = getPartnernameFromLocalStorage(secretKey);

  const monthly =
    valueDateFilter === "monthly"
      ? "bg-sekunder-60 hover:bg-sekunder-40 text-white font-medium"
      : "hover:bg-slate-200";
  const allfilter =
    valueDateFilter === ""
      ? "bg-sekunder-60 hover:bg-sekunder-40 text-white font-medium"
      : "hover:bg-slate-200";

  React.useEffect(() => {
    axios
      .get(
        API_URLS.reservasi +
          "/api/get_partner_reservation?partner_id=" +
          partnerId +
          "&date_filter=" +
          valueDateFilter +
          "&filter=success"
      )
      .then((response) => {
        let reservations = response.data.partner_reservations.data;
        console.log(reservations);

        let newReservation = [];
        for (let index = 0; index < reservations.length; index++) {
          newReservation.push({
            order_id: reservations[index].order_id,
            customer: reservations[index].partner_customer,
            total_ticket: reservations[index].quantity,
            amount_total: reservations[index].amount_total,
            date_plan: reservations[index].date_plan,
            date_order: reservations[index].created_at,
            price_adjust: reservations[index].adjust_price,
            type: reservations[index].reservation_type,
            reservation_detail: reservations[index].reservation_detail,
          });
        }
        setReservation(newReservation);
        console.log(newReservation);

        const totalTickets = newReservation.reduce((total, reservation) => {
          return total + parseInt(reservation.total_ticket);
        }, 0);

        setTotalInsentivePartner(totalTickets * 5000);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [valueDateFilter]);

  const total_resv = reservation.length;

  const formatRupiah = (number) => {
    const formattedCurrency = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(number);

    const trimmedCurrency = formattedCurrency.replace(/,00$/, "");
    return trimmedCurrency;
  };

  React.useEffect(() => {
    axios
      .get(API_URLS.cms + "/api/get-partner-billing?partner_id=" + partnerId)
      .then((response) => {
        console.log(response.data);
        let bills = response.data.partner_billing;
        let newBill = [];
        for (let index = 0; index < bills.length; index++) {
          newBill.push({
            billing_id: bills[index].billing_id,
            periode_billing: bills[index].periode_billing,
            date_due: bills[index].date_due,
            total_bill: bills[index].total_bill,
            payment_link: bills[index].payment_link,
            status: bills[index].payment_state,
          });
        }
        setListBill(newBill);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [partnerId]);

  const logoPartner = encryptStorage.getItem("encryptedpartnerLogo");
  const depositPartner = encryptStorage.getItem("deposit");
  const statusPartner = encryptStorage.getItem("statusPartner");

  reservation.forEach((resv, index) => {
    resv.reservation_detail.forEach((detail, index) => {
      totalRevenue += detail.quantity * detail.price_adjust;
      totalTagihan += detail.quantity * detail.price_unit;
    });
    totalTicket += parseInt(resv.total_ticket);
  });

  totalGross = totalRevenue - totalTagihan;

  const navigate = useNavigate();

  const handleCardClick = (category) => {
    if (category === "invoice") {
      navigate("/dashboard/invoice");
    } else if (category === "reservasi") {
      navigate("/dashboard/reservasi");
    } else if (category === "insentif") {
      navigate("/partner/insentif");
    }
  };

  //Seting FAQ dan Petunjuk Kunjungan
  const [typeInformasi, setTypeInformasi] = React.useState("faq");

  const handleTypeInformasi = (type) => {
    setTypeInformasi(type);
  };
  //Seting FAQ dan Petunjuk Kunjungan

  return (
    <>
      <LayoutMain>
        <div className="w-full px-5 md:px-10 pt-6">
          <div className="w-full h-screen">
            <div className="w-full flex flex-col mb-6">
              <div className="w-full flex items-center gap-4">
                <div className="w-fit flex flex-col">
                  <strong className="inline-block text-2xl">
                    Hi {partnerName},
                  </strong>
                  <strong className="inline-block text-lg text-gray-400">
                    Sugeng Rawuh👋
                  </strong>
                </div>
                <img
                  src={logoPartner}
                  alt="Logo Partner"
                  className="w-16 h-auto"
                />
              </div>
            </div>
            <div className="w-full flex flex-col mb-6">
              <div className="w-full flex flex-col gap-4 lg:flex-row lg:items-start lg:justify-between">
                <div className="w-full flex flex-col">
                  <strong className="inline-block text-xl mt-2 text-sekunder-60">
                    Dashboard Overview
                  </strong>
                  <strong className="inline-block mt-2 text-md">
                    {formattedDate}
                  </strong>
                </div>
                <div
                  className="w-full flex flex-col items-end gap-4"
                  title="buat reservasi"
                >
                  <div className="w-full lg:w-fit">
                    <Card className="w-full lg:w-fit min-h-fit p-4">
                      <div className="w-full flex items-center justify-between">
                        <div className="w-fit flex ">
                          <GiMoneyStack className="text-6xl text-primer-60" />
                        </div>
                        <div className="w-full grid grid-cols-1 items-start py-4 px-4  gap-0">
                          <span className="text-md text-slate-600 whitespace-nowrap">
                            Total Deposit
                          </span>
                          <strong className="text-xl text-slate-800 whitespace-nowrap">
                            {depositPartner == null || depositPartner == ""
                              ? "Rp. 0"
                              : formatRupiah(depositPartner)}
                          </strong>
                        </div>
                      </div>
                    </Card>
                  </div>
                  <NavLink
                    to={
                      statusPartner == 1 ? "#" : "/reservasi/create-reservasi"
                    }
                    className={`w-full lg:w-fit h-fit px-4 py-3 lg:rounded-full md:rounded-full  rounded-full flex items-center justify-center gap-2 font-semibold text-white text-sm transition-colors duration-200 ease-in-out whitespace-nowrap ${
                      statusPartner == 1
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-primer-60 hover:bg-primer-80 cursor-pointer"
                    }`}
                  >
                    <HiPlus className="text-base" />
                    <span className="block">Buat Pesanan</span>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="w-full flex items-center relative">
              <button
                className="px-4 py-3 w-full lg:w-fit flex items-center justify-center gap-2 bg-sekunder-60 font-semibold text-white text-sm rounded-full hover:bg-sekunder-40 transition-colors duration-200 ease-in-out"
                onClick={handleShowFilterDashboard}
              >
                <FaFilter />
                <span className="block">Filter Dashboard</span>
              </button>
              <ul
                className={`${
                  showFilterDashboard === true ? "block" : "hidden"
                } absolute top-10 left-0 bg-white py-1 border rounded-lg shadow mt-4 z-10`}
              >
                <li
                  className={`w-full px-4 py-2  text-sm cursor-pointer my-1 ${monthly}`}
                >
                  <a
                    onClick={(e) => handleDateFilter(e)}
                    className="flex items-center gap-2 text-sm whitespace-nowrap"
                    filter="monthly"
                  >
                    <FaCircleCheck
                      className={`${
                        valueDateFilter === "monthly" ? "block" : "hidden"
                      }`}
                    />
                    Bulan Ini
                  </a>
                </li>
                <li
                  className={`w-full px-4 py-2  text-sm cursor-pointer my-1 ${allfilter}`}
                >
                  <a
                    onClick={(e) => handleDateFilter(e)}
                    className="flex items-center gap-2 text-sm whitespace-nowrap"
                    filter=""
                  >
                    <FaCircleCheck
                      className={`${
                        valueDateFilter === "" ? "block" : "hidden"
                      }`}
                    />
                    Semua
                  </a>
                </li>
              </ul>
            </div>
            <div className="w-full">
              <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-4 mt-6">
                <div onClick={() => handleCardClick("Invoice Total")}>
                  <Card className="w-full min-h-fit p-4 cursor-pointer">
                    <div className="w-full flex items-center justify-between">
                      <div className="w-fit flex ">
                        <HiTicket className="text-6xl text-primer-60" />
                      </div>
                      <div className="w-full grid grid-cols-1 items-start py-4 px-4  gap-0">
                        <span className="text-md text-slate-600 whitespace-nowrap">
                          Total Ticket
                        </span>
                        <strong className="text-xl text-slate-800 whitespace-nowrap">
                          {totalTicket}
                        </strong>
                      </div>
                    </div>
                  </Card>
                </div>
                <div onClick={() => handleCardClick("invoice")}>
                  <Card className="w-full min-h-fit p-4 cursor-pointer">
                    <div className="w-full flex items-center justify-between">
                      <div className="w-fit flex ">
                        <HiCurrencyDollar className="text-6xl text-primer-60" />
                      </div>
                      <div className="w-full grid grid-cols-1 items-start py-4 px-4  gap-0">
                        <span className="text-md text-slate-600 whitespace-nowrap">
                          Total Tagihan
                        </span>
                        <strong className="text-xl text-slate-800 whitespace-nowrap">
                          {formatRupiah(totalTagihan)}
                        </strong>
                      </div>
                    </div>
                  </Card>
                </div>
                {/* <div onClick={() => handleCardClick("reservasi")}>
                  <Card className="w-full min-h-fit p-4 cursor-pointer">
                    <div className="w-full flex items-center justify-between">
                      <div className="w-fit flex ">
                        <GiMoneyStack className="text-6xl text-primer-60" />
                      </div>
                      <div className="w-full grid grid-cols-1 items-start py-4 px-4  gap-0">
                        <span className="text-md text-slate-600 whitespace-nowrap">
                          Total Pendapatan
                        </span>
                        <strong className="text-xl text-slate-800 whitespace-nowrap">
                          {formatRupiah(totalGross)}
                        </strong>
                      </div>
                    </div>
                  </Card>
                </div> */}
                <div onClick={() => handleCardClick("reservasi")}>
                  <Card className="w-full min-h-fit p-4 cursor-pointer">
                    <div className="w-full flex items-center justify-between">
                      <div className="w-fit flex ">
                        <HiCalendarDays className="text-6xl text-primer-60" />
                      </div>
                      <div className="w-full grid grid-cols-1 items-start py-4 px-4  gap-0">
                        <span className="text-md text-slate-600 whitespace-nowrap">
                          Total Insentive
                        </span>
                        <strong className="text-xl text-slate-800 whitespace-nowrap">
                          {formatRupiah(totalInsentivePartner)}
                        </strong>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
            {/* Accordion dan Petunjuk Kunjungan Start */}
            <div className="w-full mt-9">
              <ul className="w-fit flex flex-wrap text-sm font-medium text-center text-gray-500 border-b-2 border-gray-200 dark:border-gray-700 dark:text-gray-400">
                <li className="me-2">
                  <button
                    aria-current="page"
                    onClick={() => handleTypeInformasi("faq")}
                    className={`${
                      typeInformasi == "faq"
                        ? "font-semibold inline-block p-4 text-primer-60 bg-gray-100 rounded-t-lg active dark:bg-gray-800"
                        : "inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"
                    }`}
                  >
                    Faq
                  </button>
                </li>
                <li className="me-2">
                  <button
                    onClick={() => handleTypeInformasi("petunjuk-kunjungan")}
                    className={`${
                      typeInformasi == "petunjuk-kunjungan"
                        ? "font-semibold inline-block p-4 text-primer-60 bg-gray-100 rounded-t-lg active dark:bg-gray-800"
                        : "inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"
                    }`}
                  >
                    Petunjuk Kunjungan
                  </button>
                </li>
              </ul>
            </div>

            <div className="w-full mt-6">
              {typeInformasi === "faq" ? <FaqDashboard /> : <PetKunjungan />}
            </div>
            {/* Accordion dan Petunjuk Kunjungan End */}
            <div className="px-10 lg:pl-10 lg:pr-16 mt-12 py-4 border-t-2 border-t-slate-100 dark:border-t-slate-700">
              <p className="text-sm text-slate-400">
                Copyright © 2023 Aplikasi By IT SALOKA
              </p>
            </div>
          </div>
          {/* <div className="px-10 lg:pl-10 lg:pr-16 mt-12 py-4 border-t-2 border-t-slate-100 dark:border-t-slate-700">
            <p className="text-sm text-slate-400">
              Copyright © 2023 Aplikasi By IT SALOKA
            </p>
          </div> */}
        </div>
      </LayoutMain>
    </>
  );
};

export default Dashboard;
