import React from "react";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import LayoutMain from "../../layouts/layout-main";
import { FaCircle } from "react-icons/fa";
import { HiMiniMagnifyingGlass, HiCurrencyDollar } from "react-icons/hi2";
import {
  FaFilePdf,
  FaCalendarDays,
  FaCircleXmark,
  FaMoneyBill1,
  FaFileInvoiceDollar,
} from "react-icons/fa6";
import { FaFileUpload } from "react-icons/fa";
import { FaFilter } from "react-icons/fa";
import API_URLS from "../../config";
import axios from "axios";
import { Card } from "react-rainbow-components";
import TagihanPDF from "./invoice-pdf";
import Swal from "sweetalert2";

import CryptoJS from "crypto-js";
import config from "../../config";
import html2pdf from "html2pdf.js";
import { set } from "date-fns";

const Invoice = () => {
  useEffect(() => {
    document.title = "Saloka Theme Park";
  });
  //Modal Upload Pembayaran
  const [showModalUpload, setShowModalUplaod] = useState(false);
  const [billingID, setBillingID] = useState("");
  const [file, setFile] = useState(null);
  const decryptData = (encryptedData, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  };

  const getUsernameFromLocalStorage = (secretKey) => {
    const encryptedPartnerID = sessionStorage.getItem("encryptedpartnerID");
    if (encryptedPartnerID) {
      const decryptedPartnerID = decryptData(encryptedPartnerID, secretKey);
      return decryptedPartnerID;
    }
    return null;
  };

  const secretKey = config.secretKey;
  const partnerId = getUsernameFromLocalStorage(secretKey);

  const formatRupiah = (number) => {
    const formattedCurrency = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(number);

    const trimmedCurrency = formattedCurrency.replace(/,00$/, "");
    return trimmedCurrency;
  };

  const [searchBill, setSearchBill] = React.useState("");
  const [ListBill, setListBill] = React.useState([]);
  const [dataTagihanPdf, setDataTagihanPdf] = React.useState([]);
  const [todayDate, setTodayDate] = React.useState(new Date());
  const [jatuhTempo, setJatuhTempo] = React.useState(new Date());
  const [insentive, setInsentive] = React.useState(0);
  const [pphDuaTiga, setPphDuaTiga] = React.useState(0);
  const [totalTagihanInvoice, setTotalTagihanInvoice] = React.useState(0);
  const [tagihanBelumDibayar, setTagihanBelumDibayar] = React.useState(0);
  const [tagihanTerbayar, setTagihanTerbayar] = React.useState(0);
  const [totalTagihan, setTotalTagihan] = React.useState(0);
  const [totalInsentifBersih, setTotalInsentifBersih] = React.useState(0);
  let dueDate;
  const [detailPartner, setDetailPartner] = useState({});

  // ListBill.forEach((bill, index) => {
  //   if (bill.status !== "paid" && bill.status !== "created") {
  //     tagihanBelumDibayar += bill.total_bill;
  //   } else if (bill.status === "paid") {
  //     tagihanTerbayar += bill.total_bill;
  //   }
  //   totalTagihan += bill.total_bill;
  // });

  React.useEffect(() => {
    axios
      .get(
        API_URLS.cms +
          "/api/get-partner-billing?partner_id=" +
          partnerId +
          "&id_billing=" +
          searchBill
      )
      .then((response) => {
        let bills = response.data.partner_billing;
        let newBill = [];
        for (let index = 0; index < bills.length; index++) {
          newBill.push({
            id: bills[index].id,
            billing_id: bills[index].billing_id,
            periode_billing: bills[index].periode_billing,
            date_due: bills[index].date_due,
            total_bill: bills[index].total_bill,
            payment_link: bills[index].payment_link,
            status: bills[index].payment_state,
            insentive: bills[index].insentive,
            pph23: bills[index].pph23,
            final_bill: bills[index].final_bill,
            total_ticket: bills[index].total_ticket,
          });
        }
        setListBill(newBill);

        if (response.data.partner_billing.length > 0) {
          let detaiInvoiceBilling = response.data.partner_billing;
          console.log(detaiInvoiceBilling);

          //Total Tagihan Yang Belum di Bayar Start
          const unpaidInvoices = detaiInvoiceBilling.filter(
            (invoice) => invoice.payment_state !== "paid"
          );

          const totalUnpaidBilling = unpaidInvoices.reduce((total, invoice) => {
            return total + invoice.final_bill;
          }, 0);

          setTagihanBelumDibayar(totalUnpaidBilling);
          //Total Tagihan Yang Belum di Bayar End

          //Total Tagihan Yang Sudah Belum di Bayar Start
          const paidInvoices = detaiInvoiceBilling.filter(
            (invoice) => invoice.payment_state === "paid"
          );

          const totalPaidBilling = paidInvoices.reduce((total, invoice) => {
            return total + invoice.final_bill;
          }, 0);

          setTagihanTerbayar(totalPaidBilling);

          //Total Tagihan Yang Sudah Belum di Bayar End

          //Total Tagihan Start
          const totalBilling = detaiInvoiceBilling.reduce((total, invoice) => {
            return total + invoice.final_bill;
          }, 0);

          setTotalTagihan(totalBilling);
          //Total Tagihan End

          setDataTagihanPdf(response.data.partner_billing.slice(-1)[0]);
          setInsentive(response.data.partner_billing.slice(-1)[0].insentive);
          let tglJatuhTempo = new Date(
            response.data.partner_billing.slice(-1)[0].date_due
          );
          setJatuhTempo(tglJatuhTempo);
          setPphDuaTiga(response.data.partner_billing.slice(-1)[0].pph23);
          setTotalTagihanInvoice(
            response.data.partner_billing.slice(-1)[0].final_bill
          );

          let totalInsentif =
            parseInt(response.data.partner_billing.slice(-1)[0].insentive) -
            parseInt(response.data.partner_billing.slice(-1)[0].pph23);
          setTotalInsentifBersih(totalInsentif);

          //Set Tanggal Date Today Start
          let getBulanJatuhTempo = new Date(
            response.data.partner_billing.slice(-1)[0].date_due
          ).getMonth();
          const bulanSebelumJatuhTempo =
            getBulanJatuhTempo > 0 ? getBulanJatuhTempo - 1 : 11;

          let tanggalToday = new Date();
          let tahunToday = tanggalToday.getFullYear();
          let dateToday = new Date(tahunToday, bulanSebelumJatuhTempo, 26);
          setTodayDate(dateToday);
          //Set Tanggal Date Today End
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [partnerId, searchBill]);

  useEffect(() => {
    const getDetailPartner = async () => {
      try {
        const response = await axios.get(
          `${API_URLS.cms}/api/partner-detail?partner_id=${partnerId}`
        );

        if (response.status == 200) {
          if (response.data !== null) {
            setDetailPartner(response.data);
          } else {
            setDetailPartner({});
          }
        }
      } catch (error) {
        console.log(error);
        console.log(error.response);
      }
    };

    getDetailPartner();
  }, [partnerId]);

  const handleModalUploadActive = (e) => {
    const valueBillingID = e.currentTarget.getAttribute("data-billing-id");
    setBillingID(valueBillingID);
    setShowModalUplaod(true);
    console.log(valueBillingID);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUploadBuktiBayar = async (e) => {
    e.preventDefault();
    try {
      console.log(billingID);
      console.log(file);
      console.log(partnerId);

      setShowModalUplaod(false);

      if (file == null) {
        Swal.fire({
          title: "Oops!",
          text: "File Tidak Boleh Kosong, Harap Pilih File Terlebih Dahulu",
          imageUrl: "/img/STK-20230906-WA0006.webp",
          imageWidth: 200,
          imageHeight: 200,
          imageAlt: "Custom image",
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        return;
      }

      const formData = new FormData();
      formData.append("billing_id", billingID);
      formData.append("proof", file);
      formData.append("partner_id", partnerId);

      Swal.fire({
        title: "Loading!",
        text: "Proses Upload Bukti Bayar, Dimohon Tidak Keluar Dari Halaman dan Browser",
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const response = await axios.post(
        `${API_URLS.cms}/api/partner-payment`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response);
      if (response.status === 200) {
        Swal.close();
        Swal.fire({
          title: "Sukses",
          text: "Bukti Pembayaran Tagihan Berhasila di Upload",
          imageUrl: "/img/STK-20230906-WA0025.webp",
          imageWidth: 200,
          imageHeight: 200,
          imageAlt: "Custom image",
          showConfirmButton: false,
          timer: 1500,
        });
        window.location.reload();
      } else {
        Swal.close();
        Swal.fire({
          title: "Oops!",
          text: "Terjadi Kesalahan, Harap Hubungi Admin Kami",
          imageUrl: "/img/STK-20230906-WA0006.webp",
          imageWidth: 200,
          imageHeight: 200,
          imageAlt: "Custom image",
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
    } catch (error) {
      Swal.close();
      Swal.fire({
        title: "Selamat!",
        text: "Tagihan Sudah Lunas dan Bukti Bayar Sudah di Upload",
        imageUrl: "/img/STK-20230906-WA0031.webp",
        imageWidth: 200,
        imageHeight: 200,
        imageAlt: "Custom image",
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      setFile(null);
      console.error(error);
    }
  };

  //Setting Pdf Start
  const [openPdf, setOpenPdf] = useState(false);
  const generatePDF = async () => {
    const input = document.getElementById("download-pdf");
    setOpenPdf(true);
    try {
      const options = {
        margin: 10,
        filename: "generated-pdf.pdf",
        html2canvas: {
          scale: 2,
          logging: true,
          letterRendering: true,
          useCORS: true,
          scrollX: 0,
          scrollY: 0,
        },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
          compressPDF: true,
        },
      };

      const pdfBlob = await html2pdf()
        .from(input)
        .set(options)
        .outputPdf("blob");

      if (pdfBlob.size < 500 * 1024) {
        const pdfDataUri = URL.createObjectURL(pdfBlob);
        window.open(pdfDataUri, "_blank");
        setOpenPdf(false);
      } else {
        throw new Error("Ukuran file PDF terlalu besar.");
      }

      //Opsi langsung melakukan unduh pdf
      // if (pdfBlob.size < 500 * 1024) {
      //   const pdfDataUri = URL.createObjectURL(pdfBlob);

      //   const a = document.createElement("a");
      //   a.href = pdfDataUri;
      //   a.download = options.filename;
      //   a.click();

      //   setOpenPdf(false);
      // } else {
      //   throw new Error("Ukuran file PDF terlalu besar.");
      // }
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const handleOpenPdf = () => {
    window.open("/dashboard/invoice/tagihan-pdf", "_blank");
  };

  return (
    <>
      <LayoutMain>
        <div className="w-full px-5 md:px-10 pt-6">
          <strong className="inline-block text-xl text-slate-60 text-sekunder-60">
            Tagihan
          </strong>
          <div className="max-w-full w-full py-6 rounded-lg">
            <div className="w-full flex flex-col lg:flex-row lg:items-center lg:justify-between gap-4 pb-9">
              <div className="w-full">
                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4">
                  <Card className="w-full min-h-fit p-4">
                    <div className="w-full flex items-center cursor-pointer">
                      <div className="w-fit flex ">
                        <HiCurrencyDollar className="text-6xl text-red-600" />
                      </div>
                      <div className="w-full grid grid-cols-1 items-start py-4 px-4  gap-0">
                        <span className="text-md text-slate-600 whitespace-nowrap">
                          Belum Dibayar
                        </span>
                        <strong className="text-xl text-slate-800 whitespace-nowrap">
                          {formatRupiah(tagihanBelumDibayar)}
                        </strong>
                      </div>
                    </div>
                  </Card>

                  <Card className="w-full min-h-fit p-4">
                    <div className="w-full flex items-center cursor-pointer">
                      <div className="w-fit flex ">
                        <HiCurrencyDollar className="text-6xl text-sky-500" />
                      </div>
                      <div className="w-full grid grid-cols-1 items-start py-4 px-4  gap-0">
                        <span className="text-md text-slate-600 whitespace-nowrap">
                          Tagihan Terbayar
                        </span>
                        <strong className="text-xl text-slate-800 whitespace-nowrap">
                          {formatRupiah(tagihanTerbayar)}
                        </strong>
                      </div>
                    </div>
                  </Card>

                  <Card className="w-full min-h-fit p-4">
                    <div className="w-full flex items-center cursor-pointer">
                      <div className="w-fit flex ">
                        <HiCurrencyDollar className="text-6xl text-primer-60" />
                      </div>
                      <div className="w-full grid grid-cols-1 items-start py-4 px-4  gap-0">
                        <span className="text-md text-slate-600 whitespace-nowrap">
                          Total Tagihan
                        </span>
                        <strong className="text-xl text-slate-800 whitespace-nowrap">
                          {formatRupiah(totalTagihan)}
                        </strong>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
            <span className="inline-block font-semibold whitespace-nowrap">
              Daftar Tagihan
            </span>
            <div className="w-full flex flex-col lg:flex-row lg:items-center lg:justify-between gap-4 py-2 mt-4">
              <div className="w-full flex flex-col lg:flex-row items-center gap-4">
                <div className="w-full lg:w-1/2 flex items-center">
                  <div className="w-full relative">
                    <input
                      onChange={(e) => setSearchBill(e.target.value)}
                      type="text"
                      id="serach-tabel"
                      placeholder="Cari ID Tagihan"
                      className="w-full px-10 py-3 border border-slate-300 bg-white dark:bg-slate-600 rounded-full focus:outline-none focus:border-primer-60 text-slate-800 dark:text-slate-50 text-sm focus:ring-2 focus:ring-primer-40"
                    />
                    <HiMiniMagnifyingGlass className="absolute top-3 left-3.5 text-xl" />
                  </div>
                </div>
                {/* <div className="w-full lg:w-1/2 flex items-center">
                  <button
                    type="button"
                    className="px-4 py-3 w-full lg:w-fit flex items-center justify-center gap-2 bg-sekunder-60 font-semibold text-white text-sm rounded-full hover:bg-sekunder-40 transition-colors duration-200 ease-in-out"
                  >
                    <FaFilter className="text-sm" />
                    <span className="inline-block font-semibold whitespace-nowrap">
                      Filter
                    </span>
                  </button>
                </div> */}
              </div>
              <button
                onClick={generatePDF}
                className="w-fit px-4 py-3 rounded-full flex items-center justify-center gap-2 bg-primer-60 font-semibold text-white text-sm hover:bg-primer-80 transition-colors duration-200 ease-in-out whitespace-nowrap"
              >
                <FaFilePdf className="text-base" />
                Download Tagihan
              </button>
            </div>
            <div className="w-full py-4">
              {/* Desktop Table View Start */}
              <div className="hidden xl:block overflow-auto">
                <table className="w-full">
                  <thead className="bg-slate-100 rounded-lg">
                    <tr>
                      <th className="pl-6 pr-3 py-3 text-sm font-semibold tracking-wide text-left">
                        No
                      </th>
                      <th className="p-3 text-sm font-semibold tracking-wide text-left">
                        ID Tagihan
                      </th>
                      <th className="p-3 text-sm font-semibold tracking-wide text-left">
                        Periode Tagihan
                      </th>
                      <th className="p-3 text-sm font-semibold tracking-wide text-left">
                        Total Tagihan
                      </th>
                      <th className="p-3 text-sm font-semibold tracking-wide text-left">
                        Total Insentive
                      </th>
                      <th className="p-3 text-sm font-semibold tracking-wide text-left">
                        Total Pph 23
                      </th>
                      <th className="p-3 text-sm font-semibold tracking-wide text-left">
                        Total Tiket
                      </th>
                      <th className="p-3 text-sm font-semibold tracking-wide text-left">
                        Total Akhir
                      </th>
                      <th className="p-3 text-sm font-semibold tracking-wide text-left">
                        Jatuh Tempo
                      </th>
                      <th className="pr-6 pl-3 py-3 text-sm font-semibold tracking-wide text-left">
                        Status
                      </th>
                      <th className="pr-6 pl-3 py-3 text-sm font-semibold tracking-wide text-left">
                        Aksi
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y-2 divide-slate-100 dark:divide-slate-600">
                    {ListBill.map((bill, index) => {
                      dueDate = new Date(bill.date_due);
                      return (
                        <tr className="bg-white" key={bill.billing_id}>
                          <td className="pl-6 pr-3 py-3 text-sm tracking-wide text-left whitespace-nowrap">
                            {index + 1}
                          </td>
                          <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">
                            {bill.billing_id}
                          </td>
                          <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">
                            {bill.periode_billing}
                          </td>
                          <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">
                            {formatRupiah(bill.total_bill)}
                          </td>
                          <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">
                            {formatRupiah(bill.insentive)}
                          </td>
                          <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">
                            {formatRupiah(bill.pph23)}
                          </td>
                          <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">
                            {bill.total_ticket} PAX
                          </td>
                          <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">
                            {formatRupiah(bill.final_bill)}
                          </td>
                          <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">
                            {dueDate.toLocaleDateString("id-ID", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                          </td>

                          {bill.status === "waiting_payment" ? (
                            <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">
                              <div className="w-fit flex item-center gap-2 px-3 py-2 rounded-full bg-yellow-50">
                                <FaCircle className="text-yellow-500 text-sm" />
                                <span className="font-semibold whitespace-nowrap text-yellow-500 text-xs uppercase">
                                  Menunggu Pembayaran
                                </span>
                              </div>
                            </td>
                          ) : null}
                          {bill.status === "paid" ? (
                            <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">
                              <div className="w-fit flex item-center gap-2 px-3 py-2 rounded-full bg-emerald-50">
                                <FaCircle className="text-emerald-600 text-sm" />
                                <span className="font-semibold whitespace-nowrap text-emerald-600 text-xs uppercase">
                                  Sudah Dibayar
                                </span>
                              </div>
                            </td>
                          ) : null}
                          {bill.status === "expired" ? (
                            <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">
                              <div className="w-fit flex item-center gap-2 px-3 py-2 rounded-full bg-rose-50">
                                <FaCircle className="text-rose-600 text-sm" />
                                <span className="font-semibold whitespace-nowrap text-rose-600 text-xs uppercase">
                                  Jatuh Tempo
                                </span>
                              </div>
                            </td>
                          ) : null}

                          <td className="pr-6 pl-3 py-3 text-sm tracking-wide text-left">
                            <div className="w-fit flex item-center gap-2 px-3 py-2 justify-between">
                              {bill.status == "waiting_payment" ? (
                                <button
                                  onClick={(e) => handleModalUploadActive(e)}
                                  title="Upload Bukti Bayar"
                                  className="w-8 h-8 flex items-center justify-center rounded-lg bg-sky-50 text-sky-600 hover:bg-sky-600 hover:text-white transition-colors duration-200 ease-in-out"
                                  data-billing-id={bill.id}
                                >
                                  <FaFileUpload className="text-base" />
                                </button>
                              ) : bill.status == "expired" ? (
                                <button
                                  onClick={(e) => handleModalUploadActive(e)}
                                  title="Upload Bukti Bayar"
                                  className="w-8 h-8 flex items-center justify-center rounded-lg bg-sky-50 text-sky-600 hover:bg-sky-600 hover:text-white transition-colors duration-200 ease-in-out"
                                  data-billing-id={bill.id}
                                >
                                  <FaFileUpload className="text-base" />
                                </button>
                              ) : null}
                              <button
                                className="w-8 h-8 flex items-center justify-center rounded-lg bg-amber-50 text-amber-600 hover:bg-amber-600 hover:text-white transition-colors duration-200 ease-in-out cursor-pointer"
                                title="File PPh 23"
                              >
                                <FaFileInvoiceDollar />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {/* Desktop Table View End */}

              {/* Mobile Table View Start */}
              <div className="xl:hidden w-full">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {ListBill.map((bill, index) => {
                      return (
                        <tr>
                          <td>
                            <Card className="w-full px-4 pb-3 pt-6 flex flex-col gap-3 border border-slate-300 rounded-lg my-2 cursor-pointer">
                              <div className="w-full flex items-center justify-between">
                                <strong className="text-base text-left">
                                  {bill.billing_id}
                                </strong>
                                {bill.status === "waiting_payment" ? (
                                  <span className="bg-amber-100 text-amber-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-amber-900 dark:text-amber-300">
                                    Menunggu Pembayaran
                                  </span>
                                ) : bill.status === "paid" ? (
                                  <span className="bg-emerald-100 text-emerald-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-emerald-700 dark:text-emerald-300">
                                    Sudah Dibayar
                                  </span>
                                ) : bill.status === "expired" ? (
                                  <span className="bg-rose-100 text-rose-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-rose-700 dark:text-rose-300">
                                    Expired
                                  </span>
                                ) : null}
                              </div>
                              <div className="p-4 md:p-5">
                                <ol className="relative border-s border-gray-200 dark:border-gray-600 ms-3.5 mb-4 md:mb-5">
                                  <li className="mb-8 ms-8">
                                    <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-3.5 ring-8 ring-white dark:ring-gray-700 dark:bg-gray-600">
                                      <FaCalendarDays />
                                    </span>
                                    <h3 className="flex items-start mb-1 text-lg font-semibold text-gray-900 dark:text-white">
                                      {bill.periode_billing}{" "}
                                    </h3>
                                    <time className="block mb-3 text-sm font-normal leading-none text-gray-500 dark:text-gray-400">
                                      Periode
                                    </time>
                                  </li>
                                  <li className="mb-6 ms-8">
                                    <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-3.5 ring-8 ring-white dark:ring-gray-700 dark:bg-gray-600">
                                      <FaMoneyBill1 />
                                    </span>
                                    <h3 className="flex items-start mb-1 text-lg font-semibold text-gray-900 dark:text-white">
                                      {formatRupiah(bill.total_bill)}{" "}
                                    </h3>
                                    <time className="block mb-3 text-sm font-normal leading-none text-gray-500 dark:text-gray-400">
                                      Total Tagihan
                                    </time>
                                  </li>
                                  <li className="mb-6 ms-8">
                                    <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-3.5 ring-8 ring-white dark:ring-gray-700 dark:bg-gray-600">
                                      <FaMoneyBill1 />
                                    </span>
                                    <h3 className="flex items-start mb-1 text-lg font-semibold text-gray-900 dark:text-white">
                                      {formatRupiah(bill.insentive)}{" "}
                                    </h3>
                                    <time className="block mb-3 text-sm font-normal leading-none text-gray-500 dark:text-gray-400">
                                      Total Insentive
                                    </time>
                                  </li>
                                  <li className="mb-6 ms-8">
                                    <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-3.5 ring-8 ring-white dark:ring-gray-700 dark:bg-gray-600">
                                      <FaMoneyBill1 />
                                    </span>
                                    <h3 className="flex items-start mb-1 text-lg font-semibold text-gray-900 dark:text-white">
                                      {formatRupiah(bill.pph23)}{" "}
                                    </h3>
                                    <time className="block mb-3 text-sm font-normal leading-none text-gray-500 dark:text-gray-400">
                                      Total Pph 23
                                    </time>
                                  </li>
                                  <li className="mb-6 ms-8">
                                    <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-3.5 ring-8 ring-white dark:ring-gray-700 dark:bg-gray-600">
                                      <FaMoneyBill1 />
                                    </span>
                                    <h3 className="flex items-start mb-1 text-lg font-semibold text-gray-900 dark:text-white">
                                      {formatRupiah(bill.final_bill)}{" "}
                                    </h3>
                                    <time className="block mb-3 text-sm font-normal leading-none text-gray-500 dark:text-gray-400">
                                      Total Tagihan Akhir
                                    </time>
                                  </li>
                                  <li className="ms-8">
                                    <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-3.5 ring-8 ring-white dark:ring-gray-700 dark:bg-gray-600">
                                      <FaCalendarDays />
                                    </span>
                                    <h3 className="mb-1 text-lg font-semibold text-gray-900 dark:text-white">
                                      {dueDate.toLocaleDateString("id-ID", {
                                        weekday: "long",
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                      })}
                                    </h3>
                                    <time className="block mb-3 text-sm font-normal leading-none text-gray-500 dark:text-gray-400">
                                      Jatuh Tempo
                                    </time>
                                  </li>
                                </ol>
                                {bill.status == "waiting_payment" ? (
                                  <button
                                    onClick={(e) => handleModalUploadActive(e)}
                                    data-billing-id={bill.id}
                                    className="text-white inline-flex w-full justify-center bg-emerald-700 hover:bg-emerald-800 focus:ring-4 focus:outline-none focus:ring-emerald-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-emerald-600 dark:hover:bg-emerald-700 dark:focus:ring-emerald-800"
                                  >
                                    Upload Bukti Pembayaran
                                  </button>
                                ) : bill.status == "expired" ? (
                                  <button
                                    onClick={(e) => handleModalUploadActive(e)}
                                    data-billing-id={bill.id}
                                    className="text-white inline-flex w-full justify-center bg-emerald-700 hover:bg-emerald-800 focus:ring-4 focus:outline-none focus:ring-emerald-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-emerald-600 dark:hover:bg-emerald-700 dark:focus:ring-emerald-800"
                                  >
                                    Upload Bukti Pembayaran
                                  </button>
                                ) : null}
                                <button
                                  type="button"
                                  className="text-white inline-flex w-full justify-center bg-amber-700 hover:bg-amber-800 focus:ring-4 focus:outline-none focus:ring-amber-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-amber-600 dark:hover:bg-amber-700 dark:focus:ring-amber-800"
                                >
                                  File PPh 23
                                </button>
                              </div>
                            </Card>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {/* Mobile Table View End */}
            </div>
            {/* <!-- Pagination Start --> */}
            <div className="max-w-full w-full pagination bg-white dark:bg-slate-800 px-6 py-6 rounded-b-lg">
              <ul className="flex items-center justify-end">
                <span
                  id="pagination-info"
                  className="block mr-4 text-sm text-slate-600 dark:text-slate-300"
                >
                  Showing 1 to 8 of 8 entries
                </span>
                <li>
                  <a href="" className="btn-pagination cursor-pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="" className="btn-pagination cursor-pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
            {/* <!-- Pagination End --> */}
          </div>
          <div className="px-10 lg:pl-10 lg:pr-16 py-4 border-t-2 border-t-slate-100 dark:border-t-slate-700">
            <p className="text-sm text-slate-400">
              Copyright © 2023 Aplikasi By IT SALOKA
            </p>
          </div>
        </div>
      </LayoutMain>
      <section
        className={`modal-container ${
          showModalUpload === true ? "flex" : "hidden"
        } items-center justify-center max-w-full w-full max-h-screen h-full fixed top-0 left-0 z-[9999] px-5`}
      >
        <div
          className={`show-modal w-full max-w-full md:max-w-lg rounded-lg bg-white dark:bg-slate-700 py-4 ${
            showModalUpload === true ? "active" : ""
          }`}
        >
          <div className="w-full flex items-center justify-between relative pb-4 border-b border-gray-2 px-4">
            <strong>Upload Bukti Bayar</strong>
            <button onClick={(e) => setShowModalUplaod(e)}>
              <FaCircleXmark className="text-2xl" />
            </button>
          </div>
          <div className="w-full flex flex-col items-center justify-center gap-2 pt-6 px-4">
            <form action="" className="w-full">
              <div className="w-full flex flex-col gap-6">
                <div className="w-full flex flex-col gap-3 px-2">
                  <label
                    htmlFor="password-lama"
                    className="text-sm font-medium"
                  >
                    Upload Bukti Pembayaran
                  </label>
                  <input
                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    id="file_input"
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={(e) => handleFileChange(e)}
                  />
                  <p
                    className="text-sm text-gray-500 dark:text-gray-300"
                    id="file_input_help"
                  >
                    {" "}
                    PNG atau JPG.
                  </p>
                </div>
              </div>
            </form>
          </div>
          <div className="w-full flex item-center justify-end gap-3 mt-6 px-4">
            <button
              className="close-modal px-4 py-3 rounded-lg bg-slate-300 text-slate-950 text-sm hover:bg-slate-200 w-fit"
              onClick={(e) => setShowModalUplaod(e)}
            >
              Batal
            </button>
            <button
              onClick={(e) => handleUploadBuktiBayar(e)}
              className="close-modal px-4 py-3 rounded-lg text-slate-50 text-sm bg-primer-60 hover:bg-primer-80 transition-colors duration-200 ease-in-out w-fit"
            >
              Upload
            </button>
          </div>
        </div>
      </section>

      {/* <!-- Download PDF  Start--> */}
      <section
        id="download-pdf"
        className={`${
          openPdf === false ? "hidden" : "block"
        } font-Roboto max-w-full w-full min-h-screen h-full`}
      >
        <div className="w-full">
          <img src="/img/cop-surat.png" alt="Logo Saloka" className="w-full" />
        </div>
        <div className="w-full flex flex-col items-center justify-center gap-1">
          <strong className="inline-block text-2xl uppercase pb-2 border-b border-gray-950 mt-0">
            INVOICE
          </strong>
          <strong className="text-base uppercase">
            {dataTagihanPdf.invoice}
          </strong>
        </div>
        <div className="w-full flex gap-2 mt-6">
          <strong className="text-xs">Kepada : </strong>
          <div className="w-[50%] flex flex-col gap-2">
            <strong className="text-xs uppercase">
              {detailPartner?.partner_name || "-"}
            </strong>
            {/* <strong className="text-xs uppercase">
              MENARA BCA GRAND INDONESIA
            </strong> */}
            <strong className="text-xs uppercase">
              {detailPartner?.partner_address || "-"}
            </strong>
          </div>
        </div>
        <div className="w-full flex items-center justify-end mt-7 p-0">
          <span className="text-xs">Jatuh Tempo </span>
          <span className="text-xs">
            :{" "}
            {jatuhTempo.toLocaleDateString("id-ID", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </span>
        </div>
        <div className="w-full mt-4">
          <table className="w-full">
            <thead>
              <tr>
                <th className="px-3 pb-4 text-xs bg-gray-100 text-left">No.</th>
                <th className="px-3 pb-4 text-xs bg-gray-100 text-left">
                  Keterangan
                </th>
                <th className="px-3 pb-4 text-xs bg-gray-100 text-left"></th>
                <th className="px-3 pb-4 text-xs bg-gray-100 text-left"></th>
                <th className="px-3 pb-4 text-xs bg-gray-100 text-left">
                  TOTAL{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {/* Perhitungan Total Tagihan start */}
              <tr>
                <td className="px-3 pb-4 text-sm border-y border-l border-gray-800 text-left">
                  1
                </td>
                <td className="px-3 pb-4 text-sm border-y border-gray-800 text-left">
                  {dataTagihanPdf.billing_id}/{dataTagihanPdf.periode_billing}/
                  {dataTagihanPdf.total_ticket} Tiket
                </td>
                <td className="px-3 pb-4 text-sm border-y border-gray-800 text-left"></td>
                <td className="px-3 pb-4 text-sm border-y border-gray-800 text-left whitespace-nowrap"></td>
                <td className="px-3 pb-4 text-sm border-y border-x border-gray-800 whitespace-nowrap text-right">
                  {formatRupiah(dataTagihanPdf.total_bill)}
                </td>
              </tr>
              <tr>
                <td className="px-3 pb-4 text-sm border-b border-l border-gray-800 text-left"></td>
                <td className="px-3 pb-4 text-sm border-b border-gray-800 text-left"></td>
                <td className="px-3 pb-4 text-sm border-b border-gray-800 text-left">
                  <span className="uppercase">Total Tagihan</span>
                </td>
                <td className="px-3 pb-4 text-sm border-b border-gray-800 text-left whitespace-nowrap"></td>
                <td className="px-3 pb-4 text-sm border-b border-x border-gray-800 whitespace-nowrap text-right">
                  <span className="uppercase">
                    {formatRupiah(dataTagihanPdf.total_bill)}
                  </span>
                </td>
              </tr>
              {/* Perhitungan Total Tagihan End */}
              <tr>
                <td
                  className="px-3 pb-2 text-xs border-l border-gray-800 text-left"
                  colSpan={2}
                >
                  <strong className="text-sm">
                    Perhitungan Insentif dan PPh 23
                  </strong>
                </td>
                <td className="px-3 pb-2 text-xs border-gray-800 text-left"></td>
                <td className="px-3 pb-2 text-xs border-gray-800 text-left"></td>
                <td className="px-3 pb-2 text-xs border-x border-gray-800 whitespace-nowrap text-right"></td>
              </tr>
              {/* Perhitungan Insentive start */}
              <tr>
                <td className="px-3 pb-0 text-sm border-l border-gray-800 text-left">
                  1
                </td>
                <td className="px-3 pb-0 text-sm border-gray-800 text-left">
                  Insentive
                </td>
                <td className="px-3 pb-0 text-sm border-gray-800 text-left"></td>
                <td className="px-3 pb-0 text-sm border-gray-800 text-left whitespace-nowrap">
                  {formatRupiah(insentive)}
                </td>
                <td className="px-3 pb-0 text-sm border-x border-gray-800 whitespace-nowrap text-right"></td>
              </tr>
              <tr>
                <td className="px-3 pb-3 text-xs border-l border-gray-800 text-left"></td>
                <td className="px-3 pb-3 text-xs border-gray-800 text-left">
                  {formatRupiah(5000)} x {dataTagihanPdf.total_ticket} Tiket
                </td>
                <td className="px-3 pb-3 text-xs border-gray-800 text-left"></td>
                <td className="px-3 pb-3 text-xs border-gray-800 text-left whitespace-nowrap"></td>
                <td className="px-3 pb-3 text-xs border-x border-gray-800 whitespace-nowrap text-right"></td>
              </tr>
              {/* Perhitungan Insentive End */}
              {/* Perhitungan PPh 23 start */}
              <tr>
                <td className="px-3 pb-0 text-sm border-l border-gray-800 text-left">
                  2
                </td>
                <td className="px-3 pb-0 text-sm border-gray-800 text-left">
                  PPh 23
                </td>
                <td className="px-3 pb-0 text-sm border-gray-800 text-left"></td>
                <td className="px-3 pb-0 text-sm border-gray-800 text-left whitespace-nowrap">
                  {formatRupiah(pphDuaTiga)}
                </td>
                <td className="px-3 pb-0 text-sm border-x border-gray-800 whitespace-nowrap text-right"></td>
              </tr>
              <tr>
                <td className="px-3 pb-3.5 text-xs border-l border-gray-800 text-left"></td>
                <td className="px-3 pb-3.5 text-xs border-gray-800 text-left">
                  (2% x {formatRupiah(insentive)})
                </td>
                <td className="px-3 pb-3.5 text-xs border-gray-800 text-left"></td>
                <td className="px-3 pb-3.5 text-xs border-gray-800 text-left whitespace-nowrap"></td>
                <td className="px-3 pb-3.5 text-xs border-x border-gray-800 whitespace-nowrap text-right"></td>
              </tr>
              <tr>
                <td className="px-3 pb-4 text-sm border-t border-l border-gray-800 text-left"></td>
                <td className="px-3 pb-4 text-sm border-t border-gray-800 text-left"></td>
                <td className="px-3 pb-4 text-sm border-t border-gray-800 text-left">
                  <span className="uppercase">Insentif Bersih</span>
                </td>
                <td className="px-3 pb-4 text-sm border-t border-gray-800 text-left whitespace-nowrap"></td>
                <td className="px-3 pb-4 text-sm border-t border-x border-gray-800 whitespace-nowrap text-right">
                  <span className="uppercase">
                    {formatRupiah(totalInsentifBersih)}
                  </span>
                </td>
              </tr>
              {/* Perhitungan PPh 23 End */}
              {/* Grand Total Start */}
              <tr>
                <td className="px-3 pb-3.5 text-sm border border-y border-r-0 border-gray-800 text-left font-bold"></td>
                <td className="px-3 pb-3.5 text-sm border-y border-gray-800"></td>
                <td className="px-3 pb-3.5 text-sm border-y border-gray-800 text-left uppercase font-bold whitespace-nowrap">
                  GRAND TOTAL
                </td>
                <td className="px-3 pb-3.5 text-sm border-y border-gray-800"></td>
                <td className="px-3 pb-3.5 text-sm border border-y border-gray-800 text-right font-bold whitespace-nowrap">
                  {formatRupiah(totalTagihanInvoice)}
                </td>
              </tr>
              {/* Grand Total End */}
            </tbody>
          </table>
        </div>
        <div className="w-full">
          <p className="text-xs">
            * Total Insentif bersih akan langsung dikurangkan ke tagihan bulan
            berjalan.
          </p>
        </div>
        <div className="w-full pt-6">
          <table className="w-1/2">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-t border-x border-gray-800">
                <td className="text-xs w-24 pl-3">Acc Number</td>
                <td className="text-xs">:</td>
                <td className="text-xs">008101222333306</td>
              </tr>
              <tr className="border-x border-gray-800">
                <td className="text-xs w-24 pl-3">Bank Name</td>
                <td className="text-xs">:</td>
                <td className="text-xs">BANK RAKYAT INDONESIA (BRI)</td>
              </tr>
              <tr className="border-x border-gray-800">
                <td className="text-xs w-24 pl-3">Acc Name</td>
                <td className="text-xs">:</td>
                <td className="text-xs">PT Panorama Indah Permai</td>
              </tr>
              <tr className="border-b border-x border-gray-800">
                <td className="text-xs w-24 pb-3 pl-3">Branch</td>
                <td className="text-xs pb-3">:</td>
                <td className="text-xs pb-3">KCU Salatiga</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="w-full pt-9">
          <div className="w-full flex flex-col items-end gap-10">
            <span className="text-xs">
              Kab.Semarang,{" "}
              {todayDate.toLocaleDateString("id-ID", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </span>
            <div className="w-full flex flex-col items-end gap-1 pt-10">
              <strong className="inline-block text-xs uppercase pb-2 border-b border-gray-950">
                SON LOKA PRESTI
              </strong>
              <strong className="text-xs">Finance Manager</strong>
            </div>
          </div>
        </div>
        <div className="w-full pt-9"></div>
      </section>
      {/* <!-- Download PDF End--> */}
    </>
  );
};

export default Invoice;
