import { useEffect, useState, useRef } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  HiViewGrid,
  HiOutlineChevronDown,
  HiMenuAlt2,
  HiOutlineSearch,
  HiOutlineX,
  HiOutlineTicket,
} from "react-icons/hi";
import { HiUserCircle } from "react-icons/hi";
import { TbLogout } from "react-icons/tb";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js";
import config from "../config";
import { getDetailPartner } from "../service";
import { IdleTimerProvider, useIdleTimer } from "react-idle-timer";
import { FaCircleUser, FaFileInvoiceDollar } from "react-icons/fa6";
const LayoutMain = (props) => {
  const [subMenuOpen, setSubMenuOpen] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { children } = props;
  const location = useLocation();
  const isDashboard = location.pathname === "/dashboard";
  const isReservation = location.pathname === "/dashboard/reservasi";
  const isInvoice = location.pathname === "/dashboard/invoice";
  const isCreateReservation =
    location.pathname === "/reservasi/create-reservasi";
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [detailPartner, setDetailPartner] = useState([]);

  const handleSubMenuOpen = (index) => {
    setSubMenuOpen(subMenuOpen === index ? null : index);
  };

  const handleSidebarOpen = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleLogout = () => {
    Swal.fire({
      title: "Logout",
      text: "Apakah anda yakin ingin keluar?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#169870",
      cancelButtonColor: "#cbd5e1",
      confirmButtonText: "Yakin",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.removeItem("encryptedpartnerID");
        sessionStorage.removeItem("encryptedpartnerName");
        window.location.href = "/";
      }
    });
  };

  const handleActiveDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const decryptData = (encryptedData, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  };

  const getUsernameFromLocalStorage = (secretKey) => {
    const encryptedPartnerID = sessionStorage.getItem("encryptedpartnerID");
    if (encryptedPartnerID) {
      const decryptedPartnerID = decryptData(encryptedPartnerID, secretKey);
      return decryptedPartnerID;
    }
    return null;
  };

  const getPartnernameFromLocalStorage = (secretKey) => {
    const encryptedpartnerName = sessionStorage.getItem("encryptedpartnerName");
    if (encryptedpartnerName) {
      const decryptedPartnerName = decryptData(encryptedpartnerName, secretKey);
      return decryptedPartnerName;
    }
    return null;
  };

  const secretKey = config.secretKey;
  const partnerId = getUsernameFromLocalStorage(secretKey);
  const partnerName = getPartnernameFromLocalStorage(secretKey);

  const initialTimeout = 900000;
  const [remainingTime, setRemainingTime] = useState(initialTimeout);
  const idleTimer = useIdleTimer({
    timeout: initialTimeout,
    onIdle: (idleTime) => {
      console.log(`User is idle. Idle time: ${idleTime} milliseconds`);
      sessionStorage.removeItem("encryptedpartnerID");
      sessionStorage.removeItem("encryptedpartnerName");
      window.location.href = "/";
    },
    onAction: () => {
      setRemainingTime(initialTimeout);
    },
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => Math.max(prevTime - 1000, 0));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  const formatTime = (milliseconds) => {
    const totalSeconds = Math.ceil(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes} menit ${seconds} detik`;
  };

  const navigate = useNavigate();
  const handleProfile = () => {
    navigate("/dashboard/profile");
  };

  return (
    <>
      <section className="font-Roboto max-w-full w-full min-h-screen h-full bg-slate-50 scroll-smooth">
        <IdleTimerProvider {...idleTimer}>
          <div
            className={`fixed top-0 left-0 w-64 h-full py-9 ${
              !sidebarOpen ? "sidebar overflow-auto" : "sidebar close"
            }`}
          >
            <div className="w-full max-h-screen h-full overflow-visible">
              <button className="w-full flex items-center justify-center border-none ring-0 outline-none focus:border-none focus:ring-0 active:border-none active:ring-0">
                <img src="/img/logo-saloka.png" alt="" className="h-auto" />
              </button>
              <ul className={`w-full mt-9 ${!sidebarOpen ? "pl-6" : "pl-6"}`}>
                <span
                  className={`${
                    !sidebarOpen
                      ? "inline-block pb-1 text-sm font-semibold"
                      : "hidden"
                  }`}
                >
                  Menu
                </span>
                <li className="w-full mt-2 relative">
                  <NavLink
                    to={"/dashboard"}
                    className={`text-slate-950 w-full flex items-center justify-between gap-2 group py-1 pr-4 no-underline hover:no-underline ${
                      isDashboard ? "menu-sidebar-active" : "menu-sidebar"
                    }`}
                  >
                    <div className="w-full flex items-center gap-2">
                      <HiViewGrid
                        className={`group-hover:text-sekunder-60 ${
                          !sidebarOpen ? "text-2xl" : "text-2xl"
                        } ${isDashboard ? "text-sekunder-60" : ""}`}
                      />
                      <span
                        className={`inline-block font-semibold text-sm group-hover:text-sekunder-60 ${
                          isDashboard ? "text-sekunder-60" : ""
                        }`}
                      >
                        Dashboard
                      </span>
                    </div>
                  </NavLink>
                  <ul className="sub-menu">
                    <li>
                      <NavLink
                        to={"/"}
                        className="title-submenu text-sm font-semibold hover:text-sekunder-60 cursor-pointer"
                      >
                        Dashboard
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="w-full mt-2 relative">
                  <NavLink
                    to={"/dashboard/reservasi"}
                    className={`text-slate-950 w-full flex items-center justify-between gap-2 group py-1 pr-4 no-underline hover:no-underline ${
                      isReservation || isCreateReservation
                        ? "menu-sidebar-active"
                        : "menu-sidebar"
                    }`}
                  >
                    <div className="w-full flex items-center gap-2">
                      <HiOutlineTicket
                        className={`group-hover:text-sekunder-60 ${
                          !sidebarOpen ? "text-2xl" : "text-2xl"
                        } ${
                          isReservation || isCreateReservation
                            ? "text-sekunder-60"
                            : ""
                        }`}
                      />
                      <span
                        className={`inline-block font-semibold text-sm group-hover:text-sekunder-60 ${
                          isReservation || isCreateReservation
                            ? "text-sekunder-60"
                            : ""
                        }`}
                      >
                        Reservasi
                      </span>
                    </div>
                  </NavLink>
                  <ul className="sub-menu">
                    <li>
                      <NavLink
                        to={"/dashboard/reservasi"}
                        className="title-submenu text-sm font-semibold hover:text-sekunder-60 cursor-pointer"
                      >
                        Reservasi
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="w-full mt-2 relative">
                  <NavLink
                    to={"/dashboard/invoice"}
                    className={`text-slate-950 w-full flex items-center justify-between gap-2 group py-1 pr-4 no-underline hover:no-underline ${
                      isInvoice ? "menu-sidebar-active" : "menu-sidebar"
                    }`}
                  >
                    <div className="w-full flex items-center gap-2">
                      <FaFileInvoiceDollar
                        className={`group-hover:text-sekunder-60 ${
                          !sidebarOpen ? "text-xl" : "text-xl"
                        } ${isInvoice ? "text-sekunder-60" : ""}`}
                      />
                      <span
                        className={`inline-block font-semibold text-sm group-hover:text-sekunder-60 ${
                          isInvoice ? "text-sekunder-60" : ""
                        }`}
                      >
                        Tagihan
                      </span>
                    </div>
                  </NavLink>
                  <ul className="sub-menu">
                    <li>
                      <NavLink
                        to={"/dashboard/invoice"}
                        className="title-submenu text-sm font-semibold hover:text-sekunder-60 cursor-pointer"
                      >
                        Tagihan
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div onClick={handleSidebarOpen} className="btnSidebarBack">
              <button className="w-10 h-10 z-[9999] fixed bottom-4 right-4 flex items-center justify-center rounded-full shadow-lg bg-sekunder-60">
                {!sidebarOpen ? (
                  <HiOutlineX className="w-5 h-5 text-white" />
                ) : (
                  <HiOutlineX className="w-5 h-5 text-white" />
                )}
              </button>
            </div>
          </div>
          <div className="font-Roboto home-section">
            <div className="sticky top-0 w-full h-16 px-4 md:px-10 py-10 bg-white shadow-normal z-10">
              <header className="w-full h-full flex items-center justify-between gap-4">
                <NavLink to={"/"} className="lg:hidden w-full">
                  <img
                    src="/img/logo-saloka.png"
                    alt=""
                    className="w-24 h-auto"
                  />
                </NavLink>
                <button
                  onClick={handleSidebarOpen}
                  className="p-3 flex items-center justify-center rounded-lg bg-sekunder-60 text-white hover:bg-sekunder-40"
                >
                  <HiMenuAlt2 className="text-lg" />
                </button>
                <div className="">
                  <strong className="hidden md:block text-base text-rose-600">
                    {formatTime(remainingTime)}
                  </strong>
                </div>
                <div className="w-fit">
                  <div className="relative">
                    <button
                      onClick={handleActiveDropdown}
                      className=" font-semibold text-sm w-fit flex items-center gap-2  group whitespace-nowrap"
                    >
                      <HiUserCircle className="p-1 bg-primer-60 text-4xl text-white rounded-full" />
                      <span className="hidden lg:block">{partnerName}</span>
                    </button>
                    <ul
                      className={`dropdown-menu min-w-64 w-full absolute top-full right-0 bg-white shadow-normal10 rounded-lg py-3 mt-6 hidden pointer-events-none transition-all duration-150 ease-linear z-10 ${
                        dropdownOpen ? "active" : ""
                      }`}
                    >
                      <li>
                        <button
                          onClick={handleProfile}
                          className="flex items-center gap-2 w-full px-4 py-2 my-1 active:bg-sekunder-60 font-semibold text-sm text-slate-600 dark:text-slate-300 ${activeOption === index ? 'bg-sekunder-60 hover:bg-sekunder-40 text-white' : 'hover:bg-slate-100"
                        >
                          <FaCircleUser className="text-xl" />
                          Profile
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={handleLogout}
                          className="flex items-center gap-2 w-full px-4 py-2 my-1 active:bg-sekunder-60 font-semibold text-sm text-slate-600 dark:text-slate-300 ${activeOption === index ? 'bg-sekunder-60 hover:bg-sekunder-40 text-white' : 'hover:bg-slate-100"
                        >
                          <TbLogout className="text-xl" />
                          Logout
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </header>
            </div>
            {children}
          </div>
        </IdleTimerProvider>
      </section>
    </>
  );
};

export default LayoutMain;
