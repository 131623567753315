import { useState, useEffect } from "react";
import LayoutMain from "../../layouts/layout-main";
import FloatingLabelInput from "../../components/form/floating-label/floating-label";
import { HiUser, HiPhone, HiMiniEnvelope } from "react-icons/hi2";
import ModalKonfirmasi from "../../components/modal/modal-message";
import SelectInput from "../../components/form/select-input/select-input";
import { getProvinsiList, getKotaKabupatenList } from "../../service";
import { Spinner } from "react-rainbow-components";
import { format, parseISO } from "date-fns";
import { id } from "date-fns/locale";
import API_URLS from "../../config";
import ReactLoading from "react-loading";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import { FaArrowCircleLeft } from "react-icons/fa";

import { EncryptStorage } from "encrypt-storage";
import axios from "axios";

export const encryptStorage = new EncryptStorage("//FatmaW4T!_154", {
  storageType: "sessionStorage",
});

const TransaksiTiket = () => {
  useEffect(() => {
    document.title = "Saloka Theme Park";
  });
  const decryptData = (encryptedData, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  };

  const getUsernameFromLocalStorage = (secretKey) => {
    const encryptedPartnerID = sessionStorage.getItem("encryptedpartnerID");
    if (encryptedPartnerID) {
      const decryptedPartnerID = decryptData(encryptedPartnerID, secretKey);
      return decryptedPartnerID;
    }
    return null;
  };

  const secretKey = config.secretKey;
  const partnerId = getUsernameFromLocalStorage(secretKey);

  const arrivalDate = encryptStorage.getItem("arrivalDate");
  const ticketOrders = encryptStorage.getItem("ticketOrder");

  const bill = encryptStorage.getItem("Bill");
  const originBill = encryptStorage.getItem("OriginBill");
  const parsedDate = parseISO(arrivalDate);

  const potonganHarga = parseInt(originBill - bill);

  const formattedDate = format(parsedDate, "EEEE, dd MMMM yyyy", {
    locale: id,
  });

  const [modalsOpen, setModalOpen] = useState({
    modalKonfirmasiPesanan: false,
  });

  //Render Data Dari Service
  const [selectIndexProvinsi, setSelectIndexProvinsi] = useState(0);
  const [provinsiList, setProvinsiList] = useState([]);
  const [kotaKabupatenList, setKotaKabupatenList] = useState([]);
  const [checkout, setCheckout] = useState(false);

  const formatRupiah = (number) => {
    const formattedCurrency = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(number);

    const trimmedCurrency = formattedCurrency.replace(/,00$/, "");
    return trimmedCurrency;
  };

  useEffect(() => {
    const getProvinsiData = async () => {
      const data = await getProvinsiList();
      const mappedProvinsiList = mapProvinsiToOptions(data.data);
      setProvinsiList(mappedProvinsiList);
    };

    getProvinsiData();
  }, []);

  useEffect(() => {
    const getKotaKabupatenData = async () => {
      const data = await getKotaKabupatenList(selectIndexProvinsi);
      const mappedKotaKabupatenList = mapKotaKabupatenToOptions(data.data);
      setKotaKabupatenList(mappedKotaKabupatenList);
    };
    getKotaKabupatenData();
  }, [selectIndexProvinsi]);

  const mapProvinsiToOptions = (provinsiList) => {
    return provinsiList.map((provinsi) => ({
      id: provinsi.prov_id,
      label: provinsi.prov_name,
    }));
  };

  const mapKotaKabupatenToOptions = (kotaKabupatenList) => {
    return kotaKabupatenList.map((kotaKabupaten) => ({
      id: kotaKabupaten.city_id,
      label: kotaKabupaten.city_name,
    }));
  };

  const CheckOutFunction = () => {
    Swal.fire({
      title: "Loading!",
      text: "Please wait...",
      timerProgressBar: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    setModalOpen((prevModals) => ({
      ...prevModals,
      modalKonfirmasiPesanan: false,
    }));
    axios
      .post(API_URLS.reservasi + "/api/create_partner_reservation", {
        bill: bill,
        partner_id: partnerId,
        date_plan: arrivalDate,
        ticket_order: ticketOrders,
        customer_name: name,
        customer_phone: phone,
        customer_email: email,
        customer_province: province,
        customer_city: city,
      })
      .then((response) => {
        console.log(response);
        Swal.close();
        Swal.fire({
          title: "Sukses",
          text: "Berhasil Membuat Reservasi",
          imageUrl: "img/STK-20230906-WA0025.webp",
          imageWidth: 200,
          imageHeight: 200,
          imageAlt: "Custom image",
          showConfirmButton: false,
          timer: 2500,
        });
        return (window.location.href = "/dashboard/reservasi");
      })
      .catch((error) => {
        console.log(error.message);
        if (error.message === "Request failed with status code 403") {
          Swal.close();
          Swal.fire({
            title: "Oops!",
            text: "Silahkan Lakukan Pelunasan Tagihan",
            imageUrl: "/img/STK-20230906-WA0006.webp",
            imageWidth: 200,
            imageHeight: 200,
            imageAlt: "Custom image",
            showConfirmButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/dashboard/invoice";
            }
          });
        }
      });
  };

  const actionClickHandler = () => {
    try {
      CheckOutFunction();
    } catch (error) {
      console.error("Error in actionClickHandler:", error);
    }
  };

  const [name, setName] = useState("");
  const handleNameChange = (value) => {
    setName(value.target.value);
  };

  const [phone, setPhone] = useState("");
  const handlePhoneChange = (value) => {
    setPhone(value.target.value);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    console.log("Paste event prevented");
  };
  const [email, setEmail] = useState("");
  const [emailValidate, setEmailValidate] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidEmailValidate, setIsValidEmailValidate] = useState(false);
  const [bothEmailValid, setBothEmailValid] = useState(false);

  console.log(bothEmailValid);

  useEffect(() => {
    if (isValidEmail && isValidEmailValidate && email == emailValidate) {
      setBothEmailValid(true);
    }
  }, [emailValidate, isValidEmail, isValidEmailValidate, bothEmailValid]);

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(inputEmail));
  };

  const handleEmailValidateChange = (e) => {
    const inputEmail = e.target.value;
    setEmailValidate(inputEmail);

    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the entered email matches the regex pattern
    setIsValidEmailValidate(emailRegex.test(inputEmail));
  };

  const [province, setProvince] = useState("");
  const handleProvinceChange = (value) => {
    setProvince(value);
    setCity("");
  };

  const [city, setCity] = useState("");
  const handleCityChange = (value) => {
    setCity(value);
  };

  const openModal = (modalName) => {
    setModalOpen((prevModals) => ({ ...prevModals, [modalName]: true }));
  };

  const closeModal = (modalName) => {
    setModalOpen((prevModals) => ({ ...prevModals, [modalName]: false }));
  };

  useEffect(() => {
    if (
      name !== "" &&
      phone !== "" &&
      email !== "" &&
      province !== "" &&
      city !== ""
    ) {
      setCheckout(true);
    } else {
      setCheckout(false);
    }
    console.log(checkout);
  }, [name, phone, email, province, city]);

  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/reservasi/create-reservasi");
  };

  return (
    <>
      <LayoutMain>
        <div className="w-full px-5 md:px-10 pt-6">
          <strong className="inline-block mt-2 text-xl text-sekunder-60">
            Overview Pesanan
          </strong>
          <button
            className="w-fit flex items-center gap-2 pb-2 py-6 hover:text-primer-60 transition-all ease-in-out text-slate-950"
            onClick={handleButtonClick}
          >
            <FaArrowCircleLeft className="text-xl" />
            <strong className="inline-block text-sm">Kembali</strong>
          </button>
          <div className="w-full flex items-center gap-2"></div>
          <div className="w-full flex flex-col gap-0 xl:gap-4 xl:flex-row mt-6">
            <div className="w-full py-4">
              <div className="w-full flex flex-col gap-2">
                <strong>Data Pemesan</strong>
                <span className="text-sm text-slate-600">
                  Isi data Anda dan Pastika Semua Data Terisi dengan Benar.{" "}
                  <strong className="text-red-600">
                    Kode Booking Akan dikirm ke Email Pelanggan.
                  </strong>
                </span>
              </div>
              <div className="w-full flex flex-col gap-3 mt-6">
                <FloatingLabelInput
                  id="name"
                  label="Nama Lengkap"
                  type="text"
                  onChange={(value) => handleNameChange(value)}
                >
                  <HiUser className="absolute left-3 top-4 text-xl" />
                </FloatingLabelInput>
                <FloatingLabelInput
                  id="nomor-telphone"
                  label="Nomor Telepon"
                  type="number"
                  onChange={(value) => handlePhoneChange(value)}
                >
                  <HiPhone className="absolute left-3 top-4 text-xl" />
                </FloatingLabelInput>

                <FloatingLabelInput
                  id="email"
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(value) => handleEmailChange(value)}
                  onPaste={handlePaste}
                >
                  <HiMiniEnvelope className="absolute left-3 top-4 text-xl" />
                </FloatingLabelInput>
                {!isValidEmail && email != "" ? (
                  <p className="text-red-600 text-sm">Invalid email format</p>
                ) : null}

                <FloatingLabelInput
                  id="email"
                  label="Email Validation"
                  type="email"
                  onChange={(value) => handleEmailValidateChange(value)}
                >
                  <HiMiniEnvelope className="absolute left-3 top-4 text-xl" />
                </FloatingLabelInput>
                {!isValidEmailValidate && emailValidate != "" ? (
                  <p className="text-red-600 text-sm">Invalid email format</p>
                ) : null}

                {email !== emailValidate &&
                emailValidate != "" &&
                email != "" ? (
                  <p className="text-red-600 text-sm">Email Tidak Sesuai</p>
                ) : null}
                <SelectInput
                  options={provinsiList}
                  label="Pilih Provinsi Disini"
                  placeholder="Cari Provinsi Disini"
                  onSelectChange={(index) => {
                    setSelectIndexProvinsi(index);
                    handleProvinceChange(provinsiList[index - 1].label);
                  }}
                />
                <SelectInput
                  options={kotaKabupatenList}
                  label="Pilih Kota/Kabupaten Disini"
                  placeholder="Cari Kota/Kabupaten Disini"
                  onSelectChange={(index) => {
                    let cityId = index;
                    kotaKabupatenList.map((kotaKabupaten) => {
                      if (kotaKabupaten.id === cityId) {
                        handleCityChange(kotaKabupaten.label);
                      }
                    });
                  }}
                />
              </div>
            </div>
            <div className="w-full px-4 py-4">
              <div className="w-full xl:max-w-md p-6 mt-4 bg-slate-100 dark:bg-slate-800 rounded-lg relative">
                <div className="w-10 h-10 rounded-full bg-white dark:bg-slate-900 absolute -top-6 left-[46%]"></div>
                <div className="w-full flex flex-col gap-2 pb-4 border-b-2 border-gray-200">
                  <strong className="text-sm">Detail Pelanggan</strong>
                  <div className="w-full flex items-center justify-between">
                    <span className="text-sm">Tgl Kedatangan</span>
                    <span className="text-sm whitespace-nowrap">
                      {formattedDate}
                    </span>
                  </div>
                  <div className="w-full flex items-center justify-between">
                    <span className="text-sm">Nama Pelanggan</span>
                    <span className="text-sm whitespace-nowrap">{name}</span>
                  </div>
                  <div className="w-full flex items-center justify-between">
                    <span className="text-sm">No Telpon</span>
                    <span className="text-sm whitespace-nowrap">{phone}</span>
                  </div>
                  <div className="w-full flex items-center justify-between">
                    <span className="text-sm">Email</span>
                    <span className="text-sm whitespace-nowrap">{email}</span>
                  </div>
                  <div className="w-full flex items-center justify-between">
                    <span className="text-sm">Alamat</span>
                    <span className="text-sm whitespace-nowrap">
                      {province}, {city}
                    </span>
                  </div>
                </div>
                <div className="w-full flex flex-col gap-2 mt-6 pb-4 border-b-2 border-dashed border-gray-200">
                  <strong className="text-sm">Detail Pembayaran</strong>
                  <div className="w-full grid grid-cols-8 gap-4">
                    <div className="w-full flex flex-col gap-2 col-span-5">
                      <span className="text-sm whitespace-nowrap">Tiket</span>
                      {ticketOrders.map((ticketOrder, index) => (
                        <span className="text-sm whitespace-nowrap" key={index}>
                          {ticketOrder.quantity === 0 ? null : (
                            <span>{ticketOrder.ticket_name}</span>
                          )}
                        </span>
                      ))}
                    </div>
                    <div className="w-full flex flex-col  gap-2">
                      <span className="text-sm whitespace-nowrap">Qty</span>
                      {ticketOrders.map((ticketOrder, index) => (
                        <span className="text-sm whitespace-nowrap" key={index}>
                          {ticketOrder.quantity === 0 ? null : (
                            <span>{ticketOrder.quantity}</span>
                          )}
                        </span>
                      ))}
                    </div>
                    <div className="w-full flex flex-col gap-2 col-span-2">
                      <span className="text-sm whitespace-nowrap">
                        Subtotal
                      </span>
                      {ticketOrders.map((ticketOrder, index) => (
                        <span className="text-sm whitespace-nowrap" key={index}>
                          {ticketOrder.quantity >= 20 &&
                          ticketOrder.quantity <= 99 ? (
                            <span>
                              {formatRupiah(
                                ticketOrder.quantity * (ticketOrder.price * 0.8)
                              )}
                            </span>
                          ) : ticketOrder.quantity >= 100 &&
                            ticketOrder.quantity <= 999 ? (
                            <span>
                              {formatRupiah(
                                ticketOrder.quantity *
                                  (ticketOrder.price * 0.75)
                              )}
                            </span>
                          ) : ticketOrder.quantity >= 1000 ? (
                            <span>
                              {formatRupiah(
                                ticketOrder.quantity * (ticketOrder.price * 0.7)
                              )}
                            </span>
                          ) : (
                            <span>
                              {formatRupiah(
                                ticketOrder.quantity * ticketOrder.price_adjust
                              )}
                            </span>
                          )}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="w-full flex flex-col gap-2 py-4 border-b-2 border-dashed border-gray-200">
                  <div className="w-full flex items-center justify-between">
                    <div className="w-3/4 flex flex-col gap-2">
                      {/* <span className="text-sm">Total</span> */}
                      {/* <span className="text-sm">Potongan</span> */}
                      <span className="text-sm">Tagihan</span>
                    </div>

                    <div className="w-1/4 flex flex-col gap-2 items-end">
                      {/* <span className="text-sm">{formatRupiah(originBill)}</span>
                    <span className="text-sm">-{formatRupiah(potonganHarga)}</span> */}
                      <span className="text-sm whitespace-nowrap">
                        {formatRupiah(bill)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-full my-2">
                  <button
                    onClick={() => openModal("modalKonfirmasiPesanan")}
                    className={`w-full px-4 py-3 rounded-full bg-gray-200 text-gray-400 text-center font-semibold my-1 ${
                      checkout
                        ? "bg-primer-60 text-white hover:bg-primer-80"
                        : "bg-gray-200 text-gray-400"
                    }`}
                    disabled={!checkout}
                  >
                    Checkout
                  </button>
                </div>
                <div className="w-10 h-10 rounded-full bg-white dark:bg-slate-900 absolute -bottom-6 left-[46%]"></div>
              </div>
            </div>
          </div>

          <div className="px-10 lg:pl-10 lg:pr-16 mt-12 py-4 border-t-2 border-t-slate-100 dark:border-t-slate-700">
            <p className="text-sm text-slate-400">
              Copyright © 2023 Aplikasi By IT SALOKA
            </p>
          </div>
        </div>

        <ModalKonfirmasi
          isOpen={modalsOpen.modalKonfirmasiPesanan}
          onClose={() => closeModal("modalKonfirmasiPesanan")}
          title="Konfirmasi Pemesanan"
          desc={
            <div>
              <span>
                Apakah Alamat Email <strong>{email}</strong> dan Tanggal
                Kedatangan <strong>{formattedDate}</strong> Sudah Benar ?
              </span>
            </div>
          }
          img="/img/STK-20230906-WA0006.webp"
          titleBtnOK="CHECKOUT"
          titleBtnCancel="Batal"
          actionClick={actionClickHandler}
        />
      </LayoutMain>
    </>
  );
};

export default TransaksiTiket;
