import { useState, useEffect } from "react";
import React from "react";
import LayoutMain from "../../layouts/layout-main";
import { LuMinusCircle, LuPlusCircle } from "react-icons/lu";
import { Checkbox, Label } from "flowbite-react";
import { NavLink, useNavigate } from "react-router-dom";
import TiketCard from "../../components/card/card-tiket/tiket-card";
import { Card, Calendar, Application } from "react-rainbow-components";
import API_URLS from "../../config";
import Swal from "sweetalert2";
import { OutlinedInput } from "@mui/material";
import { FaArrowCircleLeft } from "react-icons/fa";

import axios from "axios";
import { EncryptStorage } from "encrypt-storage";

export const encryptStorage = new EncryptStorage("//FatmaW4T!_154", {
  storageType: "sessionStorage",
});

const CreateReservetion = () => {
  useEffect(() => {
    document.title = "Saloka Theme Park";
  });

  const today = new Date(
    new Date(
      new Date().toLocaleString("en", { timeZone: "Asia/Jakarta" })
    ).toLocaleString("en", { timeZone: "Asia/Jakarta" })
  );
  const [tnc, setTnC] = useState(false);
  const [bookingDate, setBookingDate] = useState(today);
  const year1 = bookingDate.getFullYear();
  const month1 = String(bookingDate.getMonth() + 1).padStart(2, "0");
  const day1 = String(bookingDate.getDate()).padStart(2, "0");
  const formattedbookingDate = `${year1}-${month1}-${day1}`;
  const [ticketOrder, setTicketOrder] = React.useState([]);
  const ticketCount = Array.from(Array(ticketOrder.length).keys());
  // const Tier = [20, 25, 30];

  const [maxDate, setmaxDate] = React.useState();
  React.useEffect(() => {
    axios
      .get("https://servicecms.salokapark.app/api/partner/getmaxdate")
      .then((response) => {
        setmaxDate(new Date(response.data.maxdate));
      })
      .catch((e) => {});
  }, []);

  const handleAcceptTnC = () => {
    setTnC(!tnc);
  };

  const formatRupiah = (number) => {
    const formattedCurrency = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(number);

    const trimmedCurrency = formattedCurrency.replace(/,00$/, "");
    return trimmedCurrency;
  };

  const rainbowTheme = {
    rainbow: {
      palette: {
        brand: "#169870",
      },
    },
  };

  const [totalTicket, setTotalTicket] = React.useState(0);

  const getTotalTicket = () => {
    let total = 0;
    ticketOrder.forEach((ticket) => {
      total += ticket.quantity;
    });
    setTotalTicket(total);
  };

  const [minQtyReq, setMinQtyReq] = React.useState(false);
  const checkQuantity = () => {
    getTotalTicket();
    let condition = [];
    ticketOrder.forEach((ticket, index) => {
      if (ticket.quantity === 0) {
        condition.push(false);
      } else if (ticket.quantity !== 0 && ticket.quantity >= ticket.min_qty) {
        condition.push(true);
      } else {
        condition.push(false);
      }
    });
    let checker = (arr) => arr.every((v) => v === true);
    if (checker(condition)) {
      setMinQtyReq(true);
    } else {
      setMinQtyReq(false);
    }
  };

  const handleArrivalDate = (value) => {
    setBookingDate(value);
  };

  React.useEffect(() => {
    encryptStorage.setItem("arrivalDate", formattedbookingDate);
    const localTicketOrder = window.sessionStorage.getItem("ticketOrder");
    if (localTicketOrder) {
      window.sessionStorage.removeItem("ticketOrder");
      getTicketByDate(formattedbookingDate);
    } else {
      getTicketByDate(formattedbookingDate);
    }
  }, [bookingDate]);

  const getTicketByDate = (date) => {
    axios
      .get(
        API_URLS.cms +
          "/api/partner-product?date_plan=" +
          date +
          "&partner_id=slk-partner-1-1"
      )
      .then((response) => {
        let regulerTicket = response.data.partner_ticket;
        let eventTicket = response.data.partner_ticket_event;
        let newTicket = [];
        for (let index = 0; index < regulerTicket.length; index++) {
          newTicket.push({
            ticket_id: regulerTicket[index].id,
            ticket_name: regulerTicket[index].partner_ticket_name,
            min_qty: regulerTicket[index].partner_ticket_min_qty,
            max_qty: 5000,
            quantity: 0,
            price: regulerTicket[index].partner_ticket_price,
            price_adjust: regulerTicket[index].partner_ticket_price_adjust,
            distribution: regulerTicket[index].distribution_id,
            price_up: 0,
          });
        }
        for (let index = 0; index < eventTicket.length; index++) {
          newTicket.push({
            ticket_id: eventTicket[index].id,
            ticket_name: eventTicket[index].partner_ticket_name,
            min_qty: eventTicket[index].partner_ticket_min_qty,
            max_qty: 5000,
            quantity: 0,
            price: eventTicket[index].partner_ticket_price,
            price_adjust: eventTicket[index].partner_ticket_price_adjust,
            distribution: eventTicket[index].distribution_id,
            price_up: 0,
          });
        }
        setTicketOrder(newTicket);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleQuantityTicket = (e, index) => {
    const value = parseInt(e.target.value, 10) || 0;
    let newArr = [...ticketOrder];
    newArr[index].quantity = value;
    setTicketOrder(newArr);
    encryptStorage.setItem("ticketOrder", JSON.stringify(newArr));
    checkQuantity();
  };

  const addQuantityTicket = (index) => {
    let newArr = [...ticketOrder];

    if (newArr[index].quantity < newArr[index].min_qty) {
      newArr[index].quantity += newArr[index].min_qty;
    } else if (newArr[index].quantity < newArr[index].max_qty) {
      newArr[index].quantity += 1;
    } else {
    }
    setTicketOrder(newArr);
    encryptStorage.setItem("ticketOrder", JSON.stringify(newArr));
    checkQuantity();
  };
  const subQuantityTicket = (index) => {
    let newArr = [...ticketOrder];

    if (newArr[index].quantity > 0) {
      if (newArr[index].quantity <= newArr[index].min_qty) {
        newArr[index].quantity = 0;
      } else {
        newArr[index].quantity -= 1;
      }
    }
    setTicketOrder(newArr);
    encryptStorage.setItem("ticketOrder", JSON.stringify(newArr));
    checkQuantity();
  };

  const [totalBill, setTotalBill] = React.useState(0);
  const [billDisplay, setBillDisplay] = React.useState(0);

  React.useEffect(() => {
    let subtotal = 0;
    let bill = 0;
    let display = 0;
    ticketOrder.forEach((ticket, index) => {
      if (ticket.quantity >= 20 && ticket.quantity <= 99) {
        bill += ticket.quantity * (ticket.price - ticket.price * 0.2);
      } else if (ticket.quantity >= 100 && ticket.quantity <= 999) {
        bill += ticket.quantity * (ticket.price - ticket.price * 0.25);
      } else if (ticket.quantity >= 1000) {
        bill += ticket.quantity * (ticket.price - ticket.price * 0.3);
      } else {
        bill +=
          ticket.quantity *
          (ticket.price - (ticket.price - ticket.price_adjust));
      }
    });
    setTotalBill(bill);
    setBillDisplay(display);
    encryptStorage.setItem("Bill", JSON.stringify(bill));
    encryptStorage.setItem("OriginBill", JSON.stringify(display));
  }, [ticketOrder]);

  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/dashboard/reservasi");
  };

  const [priceUp, setPriceUp] = useState(false);

  const [operationalHours, setOperationalHours] = useState("");

  const getJamOprasional = async (date) => {
    try {
      await axios
        .get(`https://servicecms.salokapark.app/api/getholidaynow`, {
          date: date,
        })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            setOperationalHours(response.data.jambuka);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(bookingDate);
    let dateSelected = new Date(bookingDate);
    let year = dateSelected.getFullYear();
    let month = String(dateSelected.getMonth() + 1).padStart(2, "0");
    let date = String(dateSelected.getDate()).padStart(2, "0");

    const dateOpartional = `${year}-${month}-${date}`;

    getJamOprasional(dateOpartional);

    console.log(dateOpartional);
  }, [bookingDate]);

  return (
    <>
      <LayoutMain>
        <div className="w-full px-5 md:px-10 pt-6">
          <div className="w-full h-screen">
            <strong className="inline-block mt-2 text-xl text-sekunder-60">
              Create Reservation
            </strong>
            <button
              className="w-fit flex items-center gap-2 pb-2 py-6 hover:text-primer-60 transition-all ease-in-out text-slate-950"
              onClick={handleButtonClick}
            >
              <FaArrowCircleLeft className="text-xl" />
              <strong className="inline-block text-sm">Kembali</strong>
            </button>
            <div className="w-full flex flex-col md:flex-row gap-4 py-6">
              <Card className="rainbow-p-around_large w-full xl:w-2/3 max-h-[400px]">
                <Application theme={rainbowTheme}>
                  <Calendar
                    variant="single"
                    id="calendar-1"
                    locale="id-ID"
                    value={bookingDate}
                    onChange={(value) => handleArrivalDate(value)}
                    minDate={today}
                    maxDate={maxDate}
                    disabledDays={[]}
                  />
                </Application>
              </Card>

              <div className="w-full h-fit flex flex-col gap-4">
                <strong className="inline-block text-base">
                  Jam Operasional Hari Ini: {operationalHours}
                </strong>
                <div className="w-full">
                  <strong className="block py-1 text-sm">
                    Tanggal Kedatangan:{" "}
                  </strong>
                  <strong className="block py-1 text-primer-60">
                    {" "}
                    {bookingDate.toLocaleDateString("id-ID", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </strong>
                </div>
                {ticketCount.map((index) => (
                  <TiketCard
                    key={index}
                    title={ticketOrder[index].ticket_name}
                    desc="Tiket terusan saloka theme park. Bebas menaiki semua wahana"
                    price={`${formatRupiah(ticketOrder[index].price)}`}
                    price_adjust={ticketOrder[index].price_adjust}
                    qty={ticketOrder[index].quantity}
                    unformat_price={ticketOrder[index].price}
                    ticket_order={ticketOrder}
                    index={index}
                    onPriceUpChange={(value) => setPriceUp(value)}
                  >
                    <div className="w-full flex items-center justify-center gap-3">
                      <LuMinusCircle
                        onClick={() => subQuantityTicket(index)}
                        className="text-3xl text-primer-60 cursor-pointer select-none"
                      />

                      <input
                        className="w-20 px-4 py-3 border border-slate-300 dark:border-slate-600 rounded-lg focus:outline-none focus:border-informasi-60 text-slate-700 dark:text-slate-50 bg-white dark:bg-slate-800 text-sm focus:ring-2 focus:ring-informasi-40"
                        autoFocus={true}
                        name="qtyTiket"
                        id="qtyTiket"
                        value={ticketOrder[index].quantity}
                        onChange={(e) => handleQuantityTicket(e, index)}
                      />

                      <LuPlusCircle
                        onClick={() => addQuantityTicket(index)}
                        className="text-3xl text-primer-60 cursor-pointer select-none"
                      />
                    </div>
                    <div className="w-full flex flex-col items-center justify-center gap-3">
                      <strong className="whitespace-nowrap select-none text-sm ">
                        {formatRupiah(ticketOrder[index].price)}
                      </strong>
                      <div className="w-full flex flex-col items-center">
                        <strong className="whitespace-nowrap text-sm">
                          Total Insentif{" "}
                          {formatRupiah(ticketOrder[index].quantity * 5000)}
                        </strong>
                        <span className="whitespace-nowrap text-sm">
                          * nilai sebelum pajak
                        </span>
                      </div>
                    </div>
                  </TiketCard>
                ))}

                <div className="w-full">
                  <div className="flex items-center gap-4 py-1">
                    <Checkbox
                      onClick={handleAcceptTnC}
                      id="agree"
                      color="teal"
                      className="cursor-pointer"
                    />
                    <Label htmlFor="agree" className="flex">
                      I agree with Term And Condition and Privacy Policy of
                      Saloka Theme Park
                    </Label>
                  </div>
                  {/* <div className="flex gap-2 py-1">
                                    <span className="text-sm ">*telah melakukan reservasi? cek status reservasi <NavLink className='text-blue-500 underline text-sm'>di sini</NavLink></span>
                                </div> */}
                </div>
                <div className="w-full">
                  <div className="full flex items-center justify-between py-1">
                    <strong className="inline-block text-lg">
                      Total Tagihan
                    </strong>
                    <strong className="text-lg">
                      {formatRupiah(totalBill)}
                    </strong>
                  </div>
                </div>
                <NavLink
                  to={`${
                    tnc && totalTicket !== 0 ? "/tiket/data-transaksi" : ""
                  }`}
                  className={`px-4 py-3 rounded-full bg-gray-200 text-gray-400 text-center font-semibold my-1 focus:ring-0 focus:outline-none active:ring-0 active:outline-none hover:text-white no-underline hover:no-underline ${
                    totalTicket !== 0 && tnc
                      ? "bg-primer-60 text-white hover:text-white hover:bg-primer-80"
                      : "bg-gray-200 text-white"
                  } ${
                    tnc && totalTicket !== 0
                      ? "no-underline hover:no-underline"
                      : "no-underline"
                  }`}
                  disabled={tnc && totalTicket !== 0 ? false : true}
                >
                  CHECKOUT
                </NavLink>
              </div>
            </div>
          </div>
          <div className="px-10 lg:pl-10 lg:pr-16 mt-12 py-4"></div>
        </div>
      </LayoutMain>
    </>
  );
};

export default CreateReservetion;
